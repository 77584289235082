





































































































































































import { Vue, Prop, Component, Inject } from "vue-property-decorator";
import { IRoomInfo } from "@/models/room/IRoomInfo";
import { DateTimeText, DateText, ElapsedTimeClock } from "ui-gallery";
import { copyToClipBoard } from "@/models/utilities/clip-board";
import { config } from "@/config";
import { container } from "tsyringe";
import { AuthService } from "@/models/auth/AuthService";

/**
 * ルーム情報を表示するためのカードコンポーネントを提供します.
 */
@Component({ components: { DateTimeText, ElapsedTimeClock, DateText } })
export default class RoomInfoCard extends Vue {
    // #region props
    @Prop({ default: null }) roomInfo?: IRoomInfo;
    // #endregion

    @Inject()
    private notify!: (message: string, timeout?: number, color?: string) => void;

    readonly authService = container.resolve(AuthService);

    /**
     * 接続番号をコピーします．
     */
    private copyNumberToClipBoard(connectionNumber: number) {
        this.notify("接続番号をコピーしました");
        copyToClipBoard(connectionNumber.toString());
    }

    /**
     * 接続ページのURLをコピーします．
     */
    private copyUrlToClipBoard(connectionNumber: number) {
        this.notify("URLをコピーしました");
        copyToClipBoard(config.connectionPageUrl + "/extern-connection-setting?connectionNumber=" + connectionNumber.toString());
    }
}
