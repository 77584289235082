import { render, staticRenderFns } from "./ChromiumPopupBlockMessageDialog.vue?vue&type=template&id=392924e4&scoped=true&"
import script from "./ChromiumPopupBlockMessageDialog.vue?vue&type=script&lang=ts&"
export * from "./ChromiumPopupBlockMessageDialog.vue?vue&type=script&lang=ts&"
import style0 from "./ChromiumPopupBlockMessageDialog.vue?vue&type=style&index=0&id=392924e4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "392924e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VDialog})
