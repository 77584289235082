

































import { Vue, Component, Watch, Inject, Ref, Prop } from "vue-property-decorator";
@Component({ components: {} })
export default class ChromiumPopupBlockDialog extends Vue {
    @Prop() private value!: boolean;
}
