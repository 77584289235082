var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2 strech-y"},[_c('div',{staticClass:"mt-2 ml-2 display-1"},[_vm._v("使用中のルーム数："+_vm._s(Object.keys(_vm.roomInfoService.rooms).filter(function (x){ return _vm.roomInfoService.rooms[x]; }).length)+"/"+_vm._s(_vm.settingService.setting.roomNum))]),_c('ItemWrapGrid',{ref:"itemWrapGrid",staticClass:"mt-4",attrs:{"items":_vm.settingService.setting.roomNum,"breakWidths":[
                    {
                        columnCount:4,
                        width:1680,
                    },
                    {
                        columnCount:3,
                        width:960,
                    },
                    {
                        columnCount:2,
                        width:480,
                    },
                    {
                        columnCount:1,
                        width:1,
                    } ]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"pa-2 strech"},[(_vm.roomInfoService.rooms[item])?_c('RoomInfoCard',{attrs:{"roomInfo":_vm.roomInfoService.rooms[item]},on:{"deleteRoom":function($event){return _vm.deleteRoom(_vm.roomInfoService.rooms[item],item)},"enterRoom":_vm.enterRoom}}):_c('v-card',{staticClass:"pa-3",staticStyle:{"height":"100%!important","min-height":"356px"}},[_c('v-card-title',[_c('v-chip',{attrs:{"color":"light-blue lighten-3"}},[_vm._v("未使用")]),_c('div',{staticClass:"text-truncate ml-2"},[_vm._v(_vm._s(("No." + item)))]),_c('v-spacer')],1),_c('v-divider',{staticClass:"ml-3 mr-3"}),_c('div',{staticClass:"relative",staticStyle:{"height":"calc(100% - 80px)"}},[_c('v-btn',{staticClass:"absolute-center",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.createRoom(item)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('strong',[_vm._v("このルームを使用する")])],1)],1)],1)],1)]}}])}),_c('ConfirmDialog',{ref:"confirmDialog"}),_c('RoomEditDialog',{ref:"roomEditDialog"}),(_vm.isMobile)?_c('IosPopupBlockMessageDialog',{model:{value:(_vm.isConnecting),callback:function ($$v) {_vm.isConnecting=$$v},expression:"isConnecting"}}):_c('ChromiumPopupBlockMessageDialog',{model:{value:(_vm.isConnecting),callback:function ($$v) {_vm.isConnecting=$$v},expression:"isConnecting"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }