



















































































































































































































































import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import { RoomInfo } from "@/models/room/RoomInfo";
import {
    AudioTester,
    NetworkTester,
    AudioOutputTester,
    DeviceStreamManager,
    DeviceMediaStreamWrapper,
    DeviceInfo,
    BrowserUtility,
    BrowserType
} from "ui-gallery";
import RoomConnectionPage from "@/components/Managements/Rooms/RoomConnectionPage.vue";
import { ConnectionSetting, QualityType } from "@/models/connection/ConnectionSetting";
import { interval, Subscription } from "rxjs";

/**
 * 接続設定ページのUIを提供します.
 */
@Component({ components: { AudioTester, AudioOutputTester, NetworkTester, RoomConnectionPage } })
export default class ConnectionSettingPanel extends Vue {
    // #region private fields
    @Prop({ default: () => new ConnectionSetting() }) private readonly connectionSetting!: ConnectionSetting;
    private streamWrapper?: DeviceMediaStreamWrapper = null as unknown as undefined;
    private roomInfo: RoomInfo = new RoomInfo();
    private deviceIntervalSubscription?: Subscription;
    private audioDevices: DeviceInfo[] = [];
    private videoDevices: DeviceInfo[] = [];
    private outputDevices: DeviceInfo[] = [];
    private qualities = [
        { name: "高 - 最高画質で通信します", id: QualityType.High },
        { name: "中 - 少し画質を落として通信量を抑えます", id: QualityType.Middle },
        { name: "低 - 画質を大幅に落として通信量を抑えます", id: QualityType.Low }
    ];
    // コンポーネントが生きているかどうか
    private isAlive = false;
    // #endregion

    // #region properties
    /**
     * 接続モード
     * @description 現在はビデオチャットモードと資料共有モードのいずれかを選択
     */
    private get mode(): number {
        if (this.connectionSetting.mode === "meeting") return 0;
        if (this.connectionSetting.mode === "voice") return 1;
        return 2;
    }
    private set mode(mode: number) {
        // :HACK ビットフラグとの変換処理を行う
        if (mode === 0) {
            this.connectionSetting.mode = "meeting";
            this.connectionSetting.isAudioEnabled = true;
            this.connectionSetting.isVideoEnabled = true;
        }
        else if (mode === 1) {
            this.connectionSetting.mode = "voice";
            this.connectionSetting.isAudioEnabled = false;
            this.connectionSetting.isVideoEnabled = true;
        }
        else if (mode === 2) {
            this.connectionSetting.mode = "documents";
            this.connectionSetting.isAudioEnabled = false;
            this.connectionSetting.isVideoEnabled = false;
        }
        this.$forceUpdate();
    }
    // #endregion

    // region methods
    public validateNickName(): boolean {
        const nickNameText = this.$refs.nickNameText as any;
        if (!nickNameText) return false;
        return nickNameText.validate(true);
    }

    public validateRoomName(): boolean {
        const nameText = this.$refs.nameText as any;
        if (!nameText) return false;
        return nameText.validate(true);
    }

    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private async created(): Promise<void> {
        this.isAlive = true;

        await this.initDevices();
        this.deviceIntervalSubscription = interval(3000).subscribe(this.updateDevices);

        if (this.connectionSetting.status !== "waiting" && this.mode === 2) {
            return;
        }
        if (this.mode === 1) {
            this.connectionSetting.isAudioEnabled = false;
        }

        await this.updateDisplayStream();
    }

    /**
     * プレビューするストリームを更新します．
     */
    private async updateDisplayStream(): Promise<void> {
        const connectionSetting = this.connectionSetting;

        if ((this.videoDevices.length === 0 || !connectionSetting.isVideoEnabled) &&
            (this.audioDevices.length === 0 || !connectionSetting.isAudioEnabled)) {
            return;
        }

        const { width, height } = (() => {
            if (this.connectionSetting.quality === QualityType.High) {
                return { width: 640, height: 480 };
            }
            else if (this.connectionSetting.quality === QualityType.Middle) {
                return { width: 480, height: 360 };
            }
            return { width: 180, height: 120 };
        })();
        this.streamWrapper?.dispose();
        this.streamWrapper = await DeviceStreamManager.getDeviceStream(
            this.videoDevices.length !== 0 && connectionSetting.isVideoEnabled,
            this.audioDevices.length !== 0 && connectionSetting.isAudioEnabled,
            connectionSetting.videoDeviceId,
            connectionSetting.audioDeviceId,
            width,
            height
        );

        // コンポーネントが死んでいたらデバイスを破棄して終了
        if (!this.isAlive && this.streamWrapper) {
            DeviceStreamManager.disposeAt(this.streamWrapper);
        }
    }

    /**
     * 破棄されるときに実行されます．
     */
    private beforeDestroy() {
        this.isAlive = false;
        DeviceStreamManager.disposeAll();
        if (this.deviceIntervalSubscription) this.deviceIntervalSubscription.unsubscribe();
    }

    /**
     * デバイス情報を更新します
     * @returns 非同期処理
     */
    private async updateDevices(): Promise<void> {
        const devices = await DeviceStreamManager.getDeviceList();
        this.initDevices();
        this.audioDevices = devices.audioDeviceList;
        this.videoDevices = devices.videoDeviceList;
        this.outputDevices = devices.outputDeviceList;
    }

    /**
     * デバイス情報を初期化し, 初期値を設定します.
     * @returns 非同期処理
     */
    private async initDevices(): Promise<void> {
        const devices = await DeviceStreamManager.getDeviceList();

        // オーディオデバイス情報の初期化
        const audioDevices = devices.audioDeviceList;
        if (audioDevices.length > 0 && !this.connectionSetting.audioDeviceId) {
            this.audioDevices = audioDevices;
            this.connectionSetting.audioDeviceId = audioDevices[0].id;
            this.connectionSetting.isAudioEnabled = true;
        }

        // ビデオデバイス情報の初期化
        const videoDevices = devices.videoDeviceList;
        if (videoDevices.length > 0 && !this.connectionSetting.videoDeviceId) {
            this.videoDevices = videoDevices;
            this.connectionSetting.videoDeviceId = videoDevices[0].id;
            this.connectionSetting.isVideoEnabled = true;
        }

        // アウトプットデバイス情報の初期化
        const outputDevices = devices.outputDeviceList;
        if (outputDevices.length > 0 && !this.connectionSetting.outputDeviceId) {
            this.outputDevices = outputDevices;
            this.connectionSetting.outputDeviceId = outputDevices[0].id;
        }
    }

    private startConnection() {
        window.open("about:blank", "zentalk_connection");
        this.$emit("connect");
    }
    // #endregion
}
