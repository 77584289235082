




































































import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import { AuthService } from "@/models/auth/AuthService";
import { RoomsManagementService } from "@/models/room/RoomManagementsService";
import RoomInfoCard from "@/components/Managements/Rooms/RoomInfoCard.vue";
import RoomEditDialog from "@/components/Managements/Rooms/RoomEditDialog.vue";
import { IRoomInfo } from "@/models/room/IRoomInfo";
import { RoomInfo } from "@/models/room/RoomInfo";
import { ConfirmDialog, ConfirmDialogContent, ItemWrapGrid, BrowserUtility, BrowserType } from "ui-gallery";
import { ConnectionSetting } from "@/models/connection/ConnectionSetting";
import { delay } from "../../../models/utilities/timer";
import { container } from "tsyringe";
import { animate } from "@/models/utilities/animate";
import Qs from "qs";
import { History } from "@/models/histories/IHistory";
import { openSubWindowAsync } from "../../../models/utilities/window";
import { config } from "@/config";
import { SettingService } from "../../../models/settings/SettingService";
import { IHistory } from "../../../models/histories/IHistory";

import ChromiumPopupBlockMessageDialog from "./poopup-block-message-daialogs/ChromiumPopupBlockMessageDialog.vue";
import IosPopupBlockMessageDialog from "./poopup-block-message-daialogs/IosPopupBlockMessageDialog.vue";

/**
 * ダッシュボードページを表示するためのビューを提供します.
 */
@Component({
    components: {
        RoomInfoCard,
        RoomEditDialog,
        ConfirmDialog,
        ItemWrapGrid,
        ChromiumPopupBlockMessageDialog,
        IosPopupBlockMessageDialog
    }
})
export default class RoomConnectionPage extends Vue {
    // #region fields
    private isShowMain = false;
    private isConnecting = false;
    // #endregion

    // #regon private properties
    /**
     * ルーム情報のサービス.
     */
    private readonly roomInfoService: RoomsManagementService = container.resolve(RoomsManagementService);

    /**
     * 認証及びアカウント情報サービス.
     */
    private readonly authService: AuthService = container.resolve(AuthService);

    /**
     * 設定サービス
     */
    private readonly settingService = container.resolve(SettingService);
    // #endregion

    @Inject()
    private readonly notify!: (mesage: string, timeout?: number, color?: string) => Promise<void>;

    private isMobile = BrowserUtility.hasFlag(BrowserType.Mobile | BrowserType.Safari);

    /**
     * コンポーネント生成時.
     */
    private created(): void {
        this.roomInfoService.fetchRooms();
    }

    /**
     * DOMの生成が完了した時に実行されます．
     */
    private async mounted(): Promise<void> {
        await animate(this.$el as HTMLElement, "opacity", "0", 0);
        await animate(this.$el as HTMLElement, "opacity", "1", 0.5, 0);
    }

    /**
     * ルームを作成します.
     * @param ルームのスロット
     */
    private async createRoom(slot: number): Promise<void> {
        const dialog = this.$refs.roomEditDialog as RoomEditDialog;
        if (!dialog) return;

        const connectionSetting = await dialog.showAsync(new ConnectionSetting({ nickName: this.authService.memberName })) as ConnectionSetting;
        if (!connectionSetting) return;
        const roomInfo = new RoomInfo({
            name: typeof connectionSetting === "string" ? (connectionSetting as string).split("|")[0] : connectionSetting.roomName,
            slot,
            mode: typeof connectionSetting === "string" ? (connectionSetting as string).split("|")[1] as "documents" | "meeting" : connectionSetting.mode
        });

        // NOTE: ルーム名が空なら番号をいれる（このやり方はよろしくないけど）
        if (roomInfo.name === "") {
            roomInfo.name = "No." + slot;
        }

        if (typeof connectionSetting === "string") {
            // 保存する場合ルーム名が返却される
            const isSuccess = await this.roomInfoService.createRoom(roomInfo);
        }
        else {
            // 作成して入室する場合接続情報が返却される
            roomInfo.status = "connecting";
            const created = await this.roomInfoService.createRoom(roomInfo);
            if (created) {
                await this.roomInfoService.createHistory(created.roomId);
                connectionSetting.roomId = created.roomId;
                this.openWindow(connectionSetting);
            }
        }
        const itemWrapGrid = this.$refs.itemWrapGrid as Vue | undefined;
        if (!itemWrapGrid) return;
        itemWrapGrid.$forceUpdate();
    }

    /**
     * ルームを削除します.
     * @param 削除するルームスロット
     */
    private async deleteRoom(room: IRoomInfo, slot: number): Promise<void> {
        const isSuccess = await this.roomInfoService.deleteRoom(room);

        if (!isSuccess) this.notify("ルームの削除に失敗しました", 4000, "error");
        const itemWrapGrid = this.$refs.itemWrapGrid as Vue | undefined;
        if (!itemWrapGrid) return;
        itemWrapGrid.$forceUpdate();
    }

    /**
    * ルームに入室します
    * @param roomInfo 入室するルーム情報
    */
    private async enterRoom(roomInfo: (RoomInfo & { history: IHistory | null })): Promise<void> {
        const dialog = this.$refs.roomEditDialog as RoomEditDialog | undefined;
        if (!dialog) return;
        // 設定情報ダイアログを開き編集した情報を取得
        const connectionSetting = await dialog.showAsync(new ConnectionSetting({
            roomName: roomInfo.name,
            roomId: roomInfo.roomId,
            nickName: this.authService.memberName,
            status: roomInfo.status,
            mode: roomInfo.mode,
            useSfu: roomInfo.useSfu
        })) as ConnectionSetting;
        if (!connectionSetting) return;

        // 保存する場合(connectionSettingはルーム名)
        if (typeof connectionSetting === "string") {
            roomInfo.name = (connectionSetting as string).split("|")[0];
            roomInfo.mode = (connectionSetting as string).split("|")[1] as "documents" | "meeting";
            const updated = await this.roomInfoService.updateRoom(roomInfo);
        }

        // NOTE: ルーム名が空なら番号をいれる（このやり方はよろしくないけど）
        if (roomInfo.name === "") {
            roomInfo.name = "No." + roomInfo.slot;
        }
        // 作成して入室する場合接続情報が返却される
        else {
            roomInfo.name = connectionSetting.roomName;
            if (roomInfo.status === "waiting") {
                roomInfo.history = await this.roomInfoService.createHistory(roomInfo.roomId) || null;
            }
            roomInfo.status = "connecting";
            roomInfo.mode = connectionSetting.mode;
            const created = await this.roomInfoService.updateRoom(roomInfo);
            if (created) {
                connectionSetting.roomId = created.roomId;
            }
            this.openWindow(connectionSetting);
        }
        const itemWrapGrid = this.$refs.itemWrapGrid as Vue | undefined;
        if (!itemWrapGrid) return;
        itemWrapGrid.$forceUpdate();
    }

    /**
     * サブウィンドウを開きます．
     * @param connectionSetting 接続情報
     * @param roomInfo ルーム情報
     */
    private async openWindow(connectionSetting: ConnectionSetting): Promise<void> {
        this.isConnecting = true;
        if (BrowserUtility.hasFlag(BrowserType.Mobile)) {
            if (connectionSetting.mode === "meeting" || connectionSetting.mode === "voice") {
                await openSubWindowAsync(`/${connectionSetting.mode === "meeting" || connectionSetting.mode === "voice" ? "connection-video-chat-mobile" : "connection-document-share-mobile"}?` + Qs.stringify(connectionSetting), "zentalk_connection",
                    "menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,resizable=yes,scrollbars=no,toolbar=no,width=1480,height=1040");
            }
            else {
                await openSubWindowAsync(`/${"connection-document-share-mobile"}?` + Qs.stringify(connectionSetting), "zentalk_connection",
                    "menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,resizable=yes,scrollbars=no,toolbar=no,width=1480,height=1040");
            }
        }
        else {
            if (connectionSetting.mode === "meeting" || connectionSetting.mode === "voice") {
                await openSubWindowAsync(`/${"connection-video-chat"}?` + Qs.stringify(connectionSetting), "zentalk_connection",
                    "menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,resizable=yes,scrollbars=no,toolbar=no,width=1480,height=1040");
            }
            else {
                await openSubWindowAsync(`/${"connection-document-share"}?` + Qs.stringify(connectionSetting), "zentalk_connection",
                    "menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,resizable=yes,scrollbars=no,toolbar=no,width=1480,height=1040");
            }
        }
        this.isConnecting = false;
    }
}
