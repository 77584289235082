





































import { Vue, Component, Mixins } from "vue-property-decorator";
import ConnectionSettingPanel from "@/components/Managements/Rooms/ConnectionSettingPanel.vue";
import { DialogBase, BrowserUtility, BrowserType } from "ui-gallery";
/**
 * ルーム情報を編集する為のダイアログコンポーネントを提供します
 */
@Component({ components: { ConnectionSettingPanel } })
export default class RoomEditDialog extends Mixins(DialogBase) {
    private isFullScreen = true;
    private closeAsSaveRoom() {
        const connectionSettingPanel = this.$refs.connectionSettingPanel as ConnectionSettingPanel;
        if (!connectionSettingPanel) return;
        if (connectionSettingPanel.validateRoomName()) {
            this.close(this.context.roomName + "|" + this.context.mode);
        }
    }

    private closeAsConnect() {
        const connectionSettingPanel = this.$refs.connectionSettingPanel as ConnectionSettingPanel;
        if (!connectionSettingPanel) return;
        if (connectionSettingPanel.validateNickName() && connectionSettingPanel.validateRoomName()) {
            this.close(this.context);
        }
    }

    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private created() {
        window.addEventListener("resize", this.onWindowSizeChanged);
        this.onWindowSizeChanged();
    }

    private beforeDestroy() {
        window.removeEventListener("resize", this.onWindowSizeChanged);
    }

    private onWindowSizeChanged() {
        this.isFullScreen = window.innerWidth <= 520 || BrowserUtility.hasFlag(BrowserType.ios);
    }
}
